import React from 'react';
import Datalist from './citylist';
import {
  Input,
} from './style';


function useStickyState(defaultValue, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = typeof window !== 'undefined' && window.localStorage.getItem(key);
    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  React.useEffect(() => {
    typeof window !== 'undefined' && window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}



const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
    }, [value]);
 
  return [value, setValue];
};

 
const App = () => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
 
  const onChange = event => setValue(event.target.value);
 
  return (
      <div>
        <Input>
        <div class="text-input">
          <input
            value={value}
            type="text"
            id="input1"
            placeholder="入力すると絞り込めます"
            onChange={onChange} 
            autocomplete="on"
            list="keywords"
          />
          <label for="input1">入力して選択</label>
          <Datalist />
        </div>
        </Input>
    </div>
  );
};
 
export default App;