import React from 'react';


const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
    }, [value]);
 
  return [value, setValue];
};
 
const App = () => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset3'
  );
 
  const onChange = event => setValue(event.target.value);
 
  return (
      <div>
        都市３<input style={{}}
          value={value}
          type="text"
          onChange={onChange} 
          autocomplete="on"
          list="keywords"
        />
        <datalist id="keywords">
          <option value="北海道札幌市" />
          <option value="北海道函館市" />
          <option value="北海道小樽市" />
          <option value="北海道旭川市" />
        </datalist>
    </div>
  );
};
 
export default App;